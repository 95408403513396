import Vue from 'vue';
import Vuex from 'vuex';


Vue.use(Vuex);

const THEMES = ['global__light_theme', 'global__dark_theme'];
export default new Vuex.Store({
    modules: {},
    state: {
        theme: 1,
        rootEl: document.documentElement,
        bodyEl: document.body,
    },
    getters: {
        theme(state) {
            return THEMES[state.theme];
        },
    },
    mutations: {},
    actions: {}
});
