<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    components: {},
    name: 'App',
    provide() {
        return {};
    },
    watch: {},
    data() {
        return {};
    },
    mounted() {
        this.$store.state.rootEl.classList.add(this.$store.getters.theme);
    },
    methods: {},
    computed: {},
    destroyed() {
    },
};
</script>

<style lang="less" scoped>
#app {
    width: 100%;
    height: 100%;
}
</style>
