import en from './en';
import zh from './cn';
import es from './es';
import tr from './tr';


export const Languages = [
    {
        key: 'en',

    },
    {
        key: 'zh',

    },
    {
        key: 'es',

    },
    {
        key: 'tr',

    }
];

export default {
    en: en,
    zh: zh
};
