<template>
    <img class="logo" src="../assets/images/header/kryptos-logo.png">
</template>

<script>
export default {
    name: 'logo',

};
</script>

<style scoped lang="less">
.logo{
    width: 140px;
    height: 55px;
}
</style>
